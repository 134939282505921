<template>
  <div class="main">
    <div class="main__background">
        <img class="main__background--img" src="../assets/textures/azul.png" alt="paint" />
    </div>
    <div class="main__header">
        <h2 class="main__header--title">AVISO DE PRIVACIDAD</h2>
        <p class="main__header--subtitle">UPIIXEL</p>
    </div>
    <div class="form">
        <p class="desc">
          UPIIXEL, en carácter de Responsable del tratamiento de los datos personales de los Usuarios de nacionalidad mexicana o ubicados en los Estados Unidos Mexicanos del sitio web www.zairabandy.com, pone a tu disposición el presente Aviso de Privacidad Integral, el cual ha sido elaborado en apego a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su regulación secundaria.
        </p>
        <br>
        <h2>IDENTIFICACIÓN DEL RESPONSABLE</h2>
        <br>
        <div class="form-group">
          <b>Nombre Comercial</b>
          <p>
            UPIIXEL
          </p>
        </div>
        <div class="form-group">
          <b>Giro</b>
          <p>
            Servicios Digitales
          </p>
        </div>
        <div class="form-group">
          <b>Teléfono</b>
          <p>
            +52 55 2411 8394
          </p>
        </div>
        <div class="form-group">
          <b>Correo Electrónico</b>
          <p>
            luis.deanda2912@gmail.com
          </p>
        </div>
        <div class="form-group">
          <b>Horario de Atención</b>
          <p>
            lunes a viernes de 10 a 13h
          </p>
        </div>
        <br>
        <h2>DATOS PERSONALES</h2>
        <br>
        <h3>LISTA DE DATOS PERSONALES SUJETOS A TRATAMIENTO</h3>
        <p>
          En www.zairabandy.com, realizamos el tratamiento de diversas categorías de datos personales. Para tu mejor entendimiento, a continuación, detallamos cuáles categorías y datos personales específicos son las que están bajo nuestro tratamiento:
        </p>
        <h4>Datos de Identificación</h4>
        <ul>
          <li>Nombre Completo</li>
          <li>País de procedencia</li>
        </ul>
        <h4>Datos de Contacto</h4>
        <ul>
          <li>Correo Electrónico</li>
          <li>Teléfono celular</li>
        </ul>
        <h4>Datos de Navegación Web</h4>
        <ul>
          <li>Fecha y hora de tu visita</li>
          <li>Información del navegador: programa y versión</li>
          <li>Páginas visitadas en nuestro sitio web</li>
          <li>Tipo de computadora o sistema operativo utilizado</li>
          <li>Ubicación: ubicación aproximada (según la red) y/o ubicación precisa (según el GPS y la red)</li>
        </ul>
        <h4>Datos Personales Sensibles</h4>
        <p>
          Te informamos que no recabamos ni realizamos el tratamiento de datos personales de carácter sensible de nuestros usuarios/clientes del sitio web www.zairabandy.com. En caso de que sea requerido un cambio al respecto, haremos de tu conocimiento dicha modificación en nuestro Aviso de Privacidad Integral.
        </p>
        <h4>¿Cómo obtenemos tus datos personales?</h4>
        <p>Los datos personales los adquirimos a través de los formularios, por lo tanto no los obtenemos si el usuario no los ingresa.</p>
        <p>Los datos de navegación web son obtenidos por medio de software con la única finalidad de monitorear y ofrecer una mejor experiencia de uso del sitio web</p>
        <br>
        <h2>USO DE COOKIES, WEB BEACONS U OTRAS TECNOLOGÍAS SIMILARES</h2>
        <p>
          En nuestro sitio web oficial www.zairabandy.com sí recabamos cookies y sí recabamos web beacons, esta información nos permite conocer:
        </p>
        <ul>
          <li>Dirección IP desde la cual nos visitas (en forma anónima)</li>
          <li>Fecha y hora de tu visita (en forma anónima)</li>
          <li>Información del navegador: programa y versión (en forma anónima)</li>
          <li>Páginas visitadas en nuestra plataforma (en forma anónima)</li>
          <li>Tipo de computadora o sistema operativo utilizado (en forma anónima)</li>
          <li>Ubicación: ubicación aproximada (según la red) y/o ubicación precisa (según el GPS y la red) (en forma anónima)</li>
        </ul>
        <p>
          En general, tu ubicación y tu actividad dentro de nuestra plataforma, para optimizar la búsqueda de tus servicios preferidos dentro de nuestro sitio web.
        </p>
        <p>
          Para oponerte a que recabemos estos datos sobre tu visita, te recomendamos consultar las guías de configuración de privacidad del navegador que utilizas.
        </p>
        <h2>ACTUALIZACIONES</h2>
        <p>
          Este Aviso de Privacidad Integral será actualizado en tiempo futuro derivado de modificaciones al marco jurídico en materia de protección de datos personales en México, cambios en el modelo de negocio en Zaira Bandy, nuevos datos personales que se recaben y/o cambios en las finalidades. Las actualizaciones y modificaciones que se realicen al presente Aviso de Privacidad Integral se harán de tu conocimiento a través de una comunicación al correo electrónico que nos proporcionaste durante el proceso de registro y a través de nuestro sitio web oficial: www.zairabandy.com
        </p>
        <br>
        <div class="form-group">
          <b>Última actualización</b>
          <p>
            01/09/2021
          </p>
        </div>
    </div>
    <div class="footer">
        <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from '../components/Footer.vue';

export default {
    data() {
        return {

        }
    },
    components: {
        Footer
    }
}
</script>

<style scoped>
.main__background {
  position: relative;
}
.main__background--img {
  width: 100%;
  height: 570px;
  object-fit: cover;
}
.main__header {
  width: 100%;
  position: absolute;
  color: var(--txt-color);
  text-align: center;
  top: 250px;
  margin: auto;
}
.main__header--title{
  font-family: 'Yeseva One';
  font-size: 70px;
  padding: 9px 0px;
  margin: 2px;
}
.main__header--subtitle{
  font-family: var(--font);
  font-weight: 500;
  padding: 9px 0px;
  line-height: 28px;
  margin: 2px;
}

/** FORM */
.form {
    width: auto;
    position: relative;
    margin-top: -100px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 100px;
    background: var(--bk-color);
    font-size: 15px;
}
.form-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.form-group p {
  padding-left: 15px;
  margin: 8px 0px;
}

@media (max-width: 800px){
  .main__header--title{
    font-size: 40px;
  }
  .form {
      padding: 20px;
      margin-left: 20px;
      margin-right: 20px;
  }
}
</style>